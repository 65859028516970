
import Vue from "vue";
import i18n from "@/i18n";
import axios from "@/plugins/axios";
import { formatFinancialAmounts } from '../../utils/format'

export default Vue.extend({
  name: "OtherBleedingDisordersLatestData",
  data: () => ({
    data: [],
    isLoading: true,
  }),
  computed: {
    selectedSurvey() {
      return this.$store.getters["agsreport/getYearSelected"];
    },
    headers() {
      return [
        {
          text: i18n.t("reports.agsreports.yearselected"),
          value: "surveyYear",
        },
        {
          text: i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.Nbofcountries"
          ),
          value: "countryCount",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FI"
          )}`,
          value: "fi",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FII"
          )}`,
          value: "fii",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },

        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FV"
          )}`,
          value: "fv",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FV&FVIII"
          )}`,
          value: "fvAndVIII",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FVII"
          )}`,
          value: "fvii",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FX"
          )}`,
          value: "fx",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FXI"
          )}`,
          value: "fxi",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.FXIII"
          )}`,
          value: "fxiii",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.Unknown"
          )}`,
          value: "bdUnk",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.GlanzmannsThrombasthenia"
          )}`,
          value: "glanzmanns",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.BernardSoulier"
          )}`,
          value: "bernard",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
        {
          text: `${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.Plateletdisorders:Other_Unknown"
          )}`,
          value: "otherPlatelet",
          formatter: (val: number): number | string => formatFinancialAmounts(val)
        },
      ];
    },
  },
  methods: {
    getData(year: number) {
      this.isLoading = true;
      axios
        .get(`Report/WFH/OtherBleedingDisordersLatestData?year=${year}`)
        .then((res) => {
          this.data = res.data;
          this.isLoading = false;
        })
        .catch((err) => console.log(err));
    },
    exportData() {
      axios
        .get(
          `Report/WFH/OtherBleedingDisordersLatestData/export?year=${this.selectedSurvey.year}`,
          { responseType: "blob" }
        )
        .then((res) => {
          const fileName = `${i18n.t(
            "navbar.menu.reportsub.ags_reports"
          )} - ${i18n.t(
            "reports.agsreports.otherBleedingDisordersLatestData.title"
          )}`;
          this.downloadExcel(fileName, res.data);
        })
        .catch((err) => console.log(err));
    },
    downloadExcel(fileName: string, data: any) {
      const blob = new Blob([data], { type: "aplication/xlsx" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName + ".xlsx";
      link.click();
    },
  },
  created() {
    if (this.selectedSurvey.year) {
      this.getData(this.selectedSurvey.year);
    }
  },
  watch: {
    selectedSurvey() {
      this.getData(this.selectedSurvey.year);
    },
  },
});
